/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export default function AboutUsText(): JSX.Element {
  return (
    <>
      <p>
        Arjen Lubach spreekt over een{' '}
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=TRNJN_GQnRA&feature=youtu.be"
          className="underline font-medium"
          rel="noreferrer">
          Digibetocratie
        </a>
        . In{' '}
        <a
          target="_blank"
          href="https://www.trouw.nl/opinie/kieslijsten-zijn-verre-van-divers-de-beta-s-ontbreken~b052997e/"
          className="underline font-medium"
          rel="noreferrer">
          Trouw
        </a>{' '}
        wordt gesproken over kieslijsten die verre van divers zijn. En in het{' '}
        <a
          target="_blank"
          href="https://www.linkedin.com/posts/marleen-van-de-kerkhof_naar-aanleiding-van-het-item-digibetocratie-activity-6767164376135864320-qowT"
          className="underline font-medium"
          rel="noreferrer">
          Financieel Dagblad
        </a>{' '}
        maakt men zich sterk voor meer bèta's in de politiek.
      </p>
      <p>
        De roep om meer bèta's in de politiek is meervoudig. Grote vraagstukken zoals AI, Cyber
        Security en de energietransitie vragen om bèta-kennis. Daarnaast is een rationele,
        objectieve en analytische bèta-blik altijd welkom bij belangrijke debatten.
      </p>
      <p>
        Wat jouw reden ook mag zijn voor meer bèta's in de Tweede Kamer: je moet ze wel kunnen
        vinden! De verschillende kandidatenlijsten geven meestal geen goed en gemakkelijk inzicht in
        de achtergronden van de kandidaten. Daarom zijn{' '}
        <a
          target="_blank"
          href="https://www.linkedin.com/feed/update/urn:li:activity:7121804768200511488/"
          className="underline font-medium"
          rel="noreferrer">
          wij
        </a>{' '}
        voor jou de lijsten van de 18 grootste partijen afgegaan en hebben we LinkedIn afgespeurd.
      </p>
      <p>Wie zijn wij?</p>
    </>
  );
}
