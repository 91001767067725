export interface Person {
  fields: {
    position?: number;
    name: string;
    study: string;
    school?: string;
  };
  linkedin?: string;
  picture?: string;
  link?: string;
  bio?: string;
}

export enum PartyType {
  VVD = 'VVD',
  CDA = 'CDA',
  D66 = 'D66',
  SP = 'SP',
  PVV = 'PVV',
  CU = 'CU',
  GLPVDA = 'Groenlinks-PvdA',
  BBB = 'BBB',
  NSC = 'NSC',
  PvdD = 'PvdD',
  FvD = 'FvD',
  SGP = 'SGP',
  Volt = 'Volt',
  DENK = 'DENK',
  FiftyPlus = '50PLUS',
  JA21 = 'JA21',
  BIJ1 = 'BIJ1',
  BVNL = 'BVNL',
}

export type PartyMap = {
  [key in PartyType]: Party;
};

export type Party = Person[];

export interface OptionsButtonOption {
  icon?: any;
  name: string;
  linkTo?: string;
  onClick?: () => void;
  className?: string;
}

export type Polls = {
  [key in PartyType]: number;
};

export enum MobileOS {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  UNKNOWN = 'UNKNOWN',
}

export interface Poll {
  min: number;
  max: number;
}