import { MobileOS } from '../types/types';

export function detectMobileOS(): MobileOS {
  const { userAgent } = navigator;
  if (/Android/i.test(userAgent)) {
    return MobileOS.ANDROID;
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return MobileOS.IOS;
  }
  return MobileOS.UNKNOWN;
}
