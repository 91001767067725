import { PartyType } from '../types/types';

export function findPartyByName(s: string): PartyType | undefined {
  const type = Object.values(PartyType).find(
    (p): boolean => p.toLocaleLowerCase() === s?.toLowerCase(),
  );
  if (type) return type;
  switch (s.toLowerCase()) {
    case '50plus':
      return PartyType.FiftyPlus;
    case 'christenunie':
      return PartyType.CU;
    case 'forumvoordemocratie':
      return PartyType.FvD;
    case 'partijvoordevrijheid':
      return PartyType.PVV;
    case 'partijvoordedieren':
      return PartyType.PvdD;
    default:
      return undefined;
  }
}

export function getPartyName(party: PartyType): string {
  switch (party) {
    case PartyType.VVD:
      return 'Volkspartij voor Vrijheid en Democratie';
    case PartyType.CDA:
      return 'Christen-Democratisch Appèl';
    case PartyType.D66:
      return 'Democraten 66';
    case PartyType.SP:
      return 'Socialistische Partij';
    case PartyType.PVV:
      return 'Partij voor de Vrijheid';
    case PartyType.CU:
      return 'ChristenUnie';
    case PartyType.GLPVDA:
      return 'GroenLinks-PvdA';
    case PartyType.PvdD:
      return 'Partij voor de Dieren';
    case PartyType.FvD:
      return 'Forum voor Democratie';
    case PartyType.SGP:
      return 'Staatkundig Gereformeerde Partij';
    case PartyType.DENK:
      return 'DENK';
    case PartyType.FiftyPlus:
      return '50PLUS';
    case PartyType.JA21:
      return 'JA21';
    case PartyType.BBB:
      return 'BoerBurgerBeweging';
    case PartyType.NSC:
      return 'Nieuw Sociaal Contract';
    case PartyType.BIJ1:
      return 'BIJ1';
    case PartyType.Volt:
      return 'Volt';
      case PartyType.BVNL:
        return 'Belang van Nederland'
    default:
      return party;
  }
}

export function headerToString(h: string): string {
  switch (h) {
    case 'position':
      return 'Positie';
    case 'name':
      return 'Naam';
    case 'study':
      return 'Studie';
    case 'school':
      return 'Onderwijsinstelling';
    case 'level':
      return 'Niveau';
    default:
      return '';
  }
}

export function getInitials(name: string): string {
  return name
    .split(' ')
    .map((part): string => part.charAt(0))
    .join('');
}
