import React from 'react';
import AboutUsText from '../components/AboutUsText';
import creators from '../data/creators.json';
import Table from '../components/Table';
import { Person } from '../types/types';

export default function AboutPage(): JSX.Element {
  return (
    <div className="rounded-2xl bg-white md:max-w-7xl w-full min-h-[600px] justify-left p-5 md:p-10 mx-auto z-0 md:my-10 md:border mb-10 border-panel-border">
      <div className=" mb-2">
        <h1 className="text-3xl font-semibold tracking-tight text-gray-900">Over ons</h1>
        <div className="mt-1 mb-4 flex flex-col gap-4">
          <AboutUsText />
        </div>
      </div>
      <div className="overflow-x-scroll no-scrollbar">
        <Table
          party={creators.map((c): Person =>
            c.picture ? { ...c, picture: `${process.env.PUBLIC_URL}${c.picture}` } : c,
          )}
        />
      </div>
    </div>
  );
}
