import React from 'react';
import { getTotalBetasWithPolls } from '../util/dataUtils';

export default function BetaFractionCircle(): JSX.Element {
  const radius = 200; // Set the radius of the circular progress bar
  const circumference = 2 * Math.PI * radius;
  const { min, max } = getTotalBetasWithPolls();
  const progressMin = circumference * (getTotalBetasWithPolls().min / 150);
  const progressMax = circumference * (getTotalBetasWithPolls().max / 150);
  const viewBoxSize = 2 * (radius + 5); // Set the viewBox size to accommodate the entire circle
  const strokeWidth = 20; // Set the stroke width of the circular progress bar

  return (
    <div className="flex w-2/3 flex-col text-sm text-center gap-4">
      <div className="flex flex-1 min-h-[200px] items-center justify-center relative">
        <svg
          className="w-full h-full absolute overflow-visible flex-none py-2"
          viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
          <circle
            stroke="#194375"
            strokeWidth={strokeWidth - 1}
            cx={viewBoxSize / 2}
            cy={viewBoxSize / 2}
            r={radius}
            fill="none"
            strokeDasharray={circumference}
          />
          <circle
            stroke="#A3D9FF"
            strokeWidth={strokeWidth}
            cx={viewBoxSize / 2}
            cy={viewBoxSize / 2}
            r={radius}
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={-progressMin} // Use a negative value to go clockwise
            transform={`rotate(-90 ${viewBoxSize / 2} ${viewBoxSize / 2})`} // Start at the top
          />
          <circle
            stroke="rgb(243 244 246)"
            strokeWidth={strokeWidth}
            cx={viewBoxSize / 2}
            cy={viewBoxSize / 2}
            r={radius}
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={-progressMax} // Use a negative value to go clockwise
            transform={`rotate(-90 ${viewBoxSize / 2} ${viewBoxSize / 2})`} // Start at the top
          />
        </svg>
        <p className="z-40 top-[50%] right-[50%] text-2xl">{`${min}-${max}`}</p>
      </div>
      <p>Zonder voorkeurstemmen komen er {`${min}-${max}`} bèta’s in de kamer</p>
    </div>
  );
}
