import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MediaModal from './MediaModal';

export default function Footer(): JSX.Element {
  const [mediaModalOpen, setMediaModalOpen] = useState(false);

  return (
    <>
      <footer className="bg-vera-dark text-white">
        <div className="flex flex-col ">
          <div className="w-full px-4  border-b border-white">
            <div className="flex flex-shrink-0 py-5 max-w-7xl mx-auto w-full">
              <p>
                Contact:{' '}
                <a
                  href="mailto:?to=info@vinddebetaopdelijst.nl"
                  target="_blank"
                  rel="noreferrer"
                  className="underline font-medium">
                  info@vinddebetaopdelijst.nl
                </a>
              </p>
            </div>
          </div>
          <div className=" w-full h-fit py-5 flex text-medium items-center px-4 desktop:px-0">
            <div className="flex md:flex-row flex-col justify-between max-w-7xl md:items-center md:mx-auto w-full">
              <p className="">
                Vinddebetaopdelijst.nl maakt geen gebruik van <br className="hidden sm:block" /> cookies
                en verzamelt geen gegevens van bezoekers.
              </p>
              <div className="flex mt-4 md:mt-0 md:gap-6 md:items-center md:flex-row flex-col">
                <Link to="/about">Over ons</Link>
                <p onClick={(): void => setMediaModalOpen(true)} className="cursor-pointer">
                  Pers
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <MediaModal open={mediaModalOpen} setOpen={setMediaModalOpen} />
    </>
  );
}
