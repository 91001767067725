import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
  children?: React.ReactNode;
}
export default function ScrollToTop({ children = <></> }: ScrollToTopProps): JSX.Element {
  const location = useLocation();

  useEffect((): void => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{children}</>;
}
