/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { PartyType, Person } from '../types/types';
import { headerToString } from '../util/stringUtils';
import InfoModal from './InfoModal';
import PersonLinks from './PersonLinks';
import { getPolls } from '../util/dataUtils';

interface TableProps {
  party: Person[];
  type?: PartyType;
}

export default function Table({ party, type }: TableProps): JSX.Element {
  const [params] = useSearchParams();
  const seats = getPolls(type);
  const headers = Object.keys(party[0]?.fields || {})
    .map(headerToString)
    .concat('');

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [modalPerson, setModalPerson] = useState<Person | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect((): void => {
    if (params.get('person')) {
      const name = decodeURIComponent(params.get('person') || '')
        .toLowerCase()
        .replaceAll(' ', '');

      const person = party.find(
        (p): boolean => p.fields.name.toLowerCase().replaceAll(' ', '') === name,
      );
      if (person) {
        openModal(person);
      }
    }
  }, []);

  const openModal = (person: Person): void => {
    setModalPerson(person);
    setModalOpen(true);
  };

  useEffect((): (() => void) => {
    window.addEventListener('resize', (): void => setWindowWidth(window.innerWidth));

    return (): void =>
      window.removeEventListener('resize', (): void => setWindowWidth(window.innerWidth));
  }, []);

  if (windowWidth > 830)
    return (
      <>
        <table
          data-testid="connections-table"
          className="min-w-full border-b border-t border-gray-400 divide-y divide-gray-400 z-0">
          <thead className="z-0">
            <tr className="z-0">
              {headers.map(
                (header): JSX.Element => (
                  <th
                    key={header}
                    className="w-fit-content py-3 pl-1 text-left text-sm font-medium text-[#6B7280] tracking-wider ">
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 z-0 text-black">
            {party.map(
              (row: Person): JSX.Element => (
                <tr
                  className={classNames('group cursor-pointer hover:bg-gray-50', {
                    'bg-vera-green-lightest-bg': row.fields.position! <= seats.min,
                    'bg-vera-green-light':
                      row.fields.position! <= seats.max && row.fields.position! > seats.min,
                  })}
                  onClick={(): void => openModal(row)}>
                  {Object.values(row.fields).map(
                    (v: string | number, index: number): JSX.Element => (
                      <td key={index} className={`py-4 text-sm  whitespace-nowrap pl-1 `}>
                        {v || '-'}
                      </td>
                    ),
                  )}
                  <td className="py-4 text-sm pl-1">
                    <PersonLinks person={row} />
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
        <InfoModal person={modalPerson} open={modalOpen} setOpen={setModalOpen} />
      </>
    );
  return (
    <>
      <div className="flex flex-col border-y border-gray-400">
        {party.map(
          (row): JSX.Element => (
            <div
              className={classNames(
                'flex flex-col border-b-2 hover:bg-gray-50 px-2  py-4 cursor-pointer',
                {
                  'border-vera-green-lightest': row.fields.position! <= seats.max,
                  'bg-vera-green-lightest-bg': row.fields.position! <= seats.min,
                  'bg-vera-green-light':
                    row.fields.position! <= seats.max && row.fields.position! > seats.min,
                  'border-gray-100': row.fields.position! > seats.max,
                },
              )}
              onClick={(): void => openModal(row)}>
              <p className="font-medium">
                {row.fields.position
                  ? `${row.fields.position}. ${row.fields.name}`
                  : row.fields.name}
              </p>
              <p className="mb-1">
                {row.fields.study}, {row.fields.school}
              </p>
              <PersonLinks person={row} />
            </div>
          ),
        )}
      </div>
      <InfoModal person={modalPerson} open={modalOpen} setOpen={setModalOpen} />
    </>
  );
}
