import React from 'react';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { Person } from '../types/types';

interface LinkProps {
  person: Person;
}
export default function PersonLinks({ person }: LinkProps): JSX.Element {
  return (
    <div
      className="flex gap-2 items-center w-fit h-fit"
      onClick={(e): void => {
        e.preventDefault();
        e.stopPropagation();
      }}>
      <img
        alt="linkedin"
        src="/assets/linkedin_icon.svg"
        className="h-6 w-6 cursor-pointer"
        onClick={(): Window | null => window.open(person.linkedin, '_blank')}
      />
      {person.link && (
        <HiOutlineExternalLink
          className="h-8 w-8  cursor-pointer mb-[1px]"
          onClick={(): Window | null => window.open(person.link)}
        />
      )}
    </div>
  );
}
