/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import ModalLayout from './ModalLayout';

interface MediaModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function MediaModal({ open, setOpen }: MediaModalProps): JSX.Element {
  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      className="bg-white min-w-[300px] w-[45rem] p-8 flex flex-col z-50 rounded-[20px] relative">
      <h1 className="text-3xl font-semibold tracking-tight text-gray-900">Pers</h1>
      <div className="mt-1 mb-8 flex flex-col gap-4 text-sm">
        <p>
          Het 'Vind de bèta op de lijst' persbericht is te downloaden via de onderstaande knop.
          Indien er andere vragen zijn of je met ons in contact wil komen, mail dan naar{' '}
          <a
            href="mailto:?to=info@vinddebetaopdelijst.nl"
            target="_blank"
            rel="noreferrer"
            className="underline font-medium">
            info@vinddebetaopdelijst.nl
          </a>
        </p>
      </div>

      <a
        href={`${process.env.PUBLIC_URL}/Persbericht-Vinddebetaopdelijst.pdf`}
        download="Persbericht-Vinddbetaopdelijst.pdf"
        className="bg-vera-green-darker text-white w-full text-lg py-4 rounded-lg font-medium flex items-center justify-center">
        Download persbericht
      </a>
    </ModalLayout>
  );
}
