import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import OverviewPage from '../pages/OverviewPage';
import AboutPage from '../pages/AboutPage';
import VeraBanner from '../components/VeraBanner';

export default function Router(): JSX.Element {
  return (
    <>
      <NavBar />
      <div className="min-h-[calc(100vh-128px)]">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route caseSensitive={false} path="/:party" element={<OverviewPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <VeraBanner />
      </div>
      <Footer />
    </>
  );
}
