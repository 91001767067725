import { Party, PartyMap, PartyType, Poll } from '../types/types';
import data from '../data/parties.json';

export function getParties(): PartyMap {
  return data.parties;
}

export function getParty(type: PartyType | undefined): Party | undefined {
  if (!type) return undefined;
  return data.parties[type];
}

export function getPolls(party: PartyType | undefined): Poll {
  if (!party) return { min: 0, max: 0 };
  return data.polls[party];
}

export function getTotalBetasWithPolls(): Poll {
  const total = { min: 0, max: 0 };
  Object.keys(data.polls).forEach((p): void => {
    const party = getParty(p as PartyType)!;
    total.min +=
      party?.filter(
        (m): boolean =>
          (m.fields?.position || 100) <= data.polls[p as keyof typeof data.polls]?.min,
      ).length || 0;
    total.max +=
      party?.filter(
        (m): boolean =>
          (m.fields?.position || 100) <= data.polls[p as keyof typeof data.polls]?.max,
      ).length || 0;
  });
  return total;
}
