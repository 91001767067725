import React from 'react';
import { detectMobileOS } from '../util/userAgentUtils';
import { MobileOS } from '../types/types';

export default function VeraBanner(): JSX.Element {
  const mobileOS = detectMobileOS();
  return (
    <div className="rounded-2xl -mb-[25%] md:h-44 overflow-hidden gap-4 md:gap-6 bg-white md:max-w-7xl w-full p-1 sm:p-5 mx-auto z-0 md:border md:mb-10 border-panel-border flex flex-col md:flex-row justify-center items-center">
      <div className="flex flex-col items-center gap-4 justify-center w-full">
        <h3 className="text-sm text-center">
          Vind de bèta op de lijst wordt mede mogelijk gemaakt door:
        </h3>
        <a
          href="https://www.veraconnect.app"
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center">
          <img
            src={`${process.env.PUBLIC_URL}/assets/veraconnect.png`}
            alt="Vera Connect"
            className="w-[50%] cursor-pointer"
          />
        </a>
      </div>
      <div className="w-2 h-[80%] rounded-2xl bg-black hidden md:block" />
      <div className="flex flex-col items-center justify-center gap-6 w-full">
        <h2 className="text-center text-sm">
          Wil je jouw telefoonnummer kunnen <br /> verwijderen uit de telefoon van een ander? Dat kan!
        </h2>
        <div className="flex gap-2 md:mb-0 mb-4">
          {mobileOS !== MobileOS.ANDROID && (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/us/app/vera-connect/id1667301680?itsct=apps_box_badge&amp;itscg=30200">
              <img
                src={`${process.env.PUBLIC_URL}/assets/badge/as.svg`}
                alt="App Store"
                className="h-[40px]"
              />
            </a>
          )}
          {mobileOS !== MobileOS.IOS && (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.VeraConnect.Frontend&hl=nl&pcampai[…]ampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                src={`${process.env.PUBLIC_URL}/assets/badge/ps.svg`}
                alt="Play Store"
                className="h-[40px]"
              />
            </a>
          )}
        </div>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/assets/veraconnect-app.png`}
        alt="Vera Connect App"
        className="w-[90%] md:w-[300px] object-top md:mt-14 md:-mr-20"
      />
    </div>
  );
}
