import { Menu, Transition } from '@headlessui/react';
import { isMobile } from 'react-device-detect';
import React, { Fragment, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OptionsButtonOption } from '../types/types';

interface OptionsButtonProps {
  children?: React.ReactNode;
  options: OptionsButtonOption[];
  className?: string;
  onClick?: any;
}

export default function OptionsButton({
  options,
  children,
  className,
  onClick,
}: OptionsButtonProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const timeoutDuration = 100;
  let timeout: any;

  const openMenu = (): void => buttonRef?.current?.click();
  const closeMenu = (): boolean | undefined =>
    dropdownRef?.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      }),
    );

  const onMouseEnter = (closed: boolean): void => {
    clearTimeout(timeout);
    if (closed) openMenu();
  };
  const onMouseLeave = (open: boolean): void => {
    if (open) timeout = setTimeout((): boolean | undefined => closeMenu(), timeoutDuration);
  };

  return (
    <Menu data-testid="user-options-menu" as="div" className="relative w-fit">
      {({ open }): JSX.Element => (
        <div className={`${open ? 'open' : ''} group`}>
          <div className="flex font-normal text-sm items-center place-items-center h-9">
            <button
              type="button"
              onMouseEnter={(): void => onMouseEnter(!open)}
              onMouseLeave={(): void => onMouseLeave(open)}
              onTouchStart={(): void => {
                buttonRef.current?.click();
                if (!isMobile) onClick?.();
              }}
              onClick={(): void => {
                buttonRef.current?.click();
                if (!isMobile) onClick?.();
              }}
              className={className}>
              {children}
            </button>
            <Menu.Button ref={buttonRef} data-testid="user-options-button" className="invisible" />
          </div>
          {open && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items
                ref={dropdownRef as any}
                onMouseEnter={(): void => onMouseEnter(false)}
                onMouseLeave={(): void => onMouseLeave(open)}
                className="absolute max-h-52 overflow-y-scroll right-0 z-10 mt-3.5 px-2 py-1 rounded-br-lg rounded-bl-lg bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map(
                  (item, index): JSX.Element => (
                    <Menu.Item key={item.name} data-testid={`user-option-${item.name}`}>
                      {({ active }): JSX.Element => (
                        <div
                          onClick={
                            item.linkTo
                              ? (): void => navigate(item.linkTo as string)
                              : (): void => item.onClick?.()
                          }
                          className={`${active ? 'bg-vera-green-lightest' : ''} ${
                            location.pathname === item.linkTo ? 'text-vera-green' : 'text-gray-700'
                          }  ${
                            index !== options.length - 1 && 'border-t-0 border-b border-gray-200'
                          } rounded cursor-pointer px-3 py-2 text-md flex place-items-center w-full gap-1  ${
                            item.className
                          }`}>
                          <div className="w-content">{item.icon}</div>
                          <div className="w-full">{item.name}</div>
                        </div>
                      )}
                    </Menu.Item>
                  ),
                )}
              </Menu.Items>
            </Transition>
          )}
        </div>
      )}
    </Menu>
  );
}
