/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export default function HomePageText(): JSX.Element {
  return (
    <>
      <p>
        Vind de bèta op de lijst is gebaseerd op afgeronde HBO- en WO-opleidingen. De grens tussen
        bèta- en andere studies is soms wat vaag, en voor sommige kandidaten was het erg lastig om
        de precieze studieachtergrond te vinden. In het{' '}
        <a
          target="_blank"
          href="https://www.trouw.nl/opinie/kieslijsten-zijn-verre-van-divers-de-beta-s-ontbreken~b052997e"
          className="underline font-medium"
          rel="noreferrer">
          Trouw
        </a>{' '}
        artikel dat ons inspireerde om deze website te maken wordt een bèta omschreven als:
        'bijvoorbeeld wis- en natuurkunde, techniek en informatica'. Wij hebben deze definitie nog
        iets breder getrokken, maar bij elke kandidaat de studie benoemd, zodat iedereen een eigen
        afweging kan maken. Mochten we ondanks onze zorgvuldige analyses toch een foutje hebben
        gemaakt, horen we het graag, stuur gerust een{' '}
        <a
          target="_blank"
          href="mailto:?to=info@vinddebetaopdelijst.nl"
          className="underline font-medium"
          rel="noreferrer">
          mailtje
        </a>
        .
      </p>
      <p>
        In verband met het grote aantal partijen die meedoen aan de verkiezingen hebben wij een
        grens getrokken op basis van de{' '}
        <a
          target="_blank"
          href="https://www.peilingennederland.nl/alle-peilingen.html"
          className="underline font-medium"
          rel="noreferrer">
          peilingen
        </a>
        . Alle partijen die door de Peilingwijzer genoemd worden omdat ze kans maken op een zetel
        zijn in ons overzicht opgenomen. Dit betekent helaas dat sommige partijen niet op deze
        website staan, zoals bijvoorbeeld de Piratenpartij (een partij die wel veel bèta's op de
        lijst heeft staan).
      </p>
    </>
  );
}
