/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { HiArrowRight, HiUserGroup } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { getParties } from '../util/dataUtils';
import HomePageText from '../components/HomePageText';
import BetaFractionCircle from '../components/BetaFractionCircle';

export default function HomePage(): JSX.Element {
  const parties = getParties();
  const navigate = useNavigate();

  return (
    <div className="rounded-2xl bg-white md:max-w-7xl w-full min-h-[600px] justify-left p-5 md:p-10 mx-auto z-0 md:my-10 md:border mb-10 border-panel-border">
      <div className=" mb-2">
        <h1 className="text-3xl font-semibold tracking-tight text-gray-900">Partijen</h1>
        <div className="flex flex-col md:flex-row mb-4 gap-4">
          <div className="mt-1 mb-8 flex flex-col gap-2 md:w-2/3">
            <HomePageText />
          </div>
          <div className=" -mt-4 md:mt-0 flex items-center justify-center mx-auto">
            <BetaFractionCircle />
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex gap-4 md:gap-8 flex-wrap ">
          {Object.keys(parties)
            .sort(
              (a, b): number =>
                parties[b as keyof typeof parties].length -
                parties[a as keyof typeof parties].length,
            )
            .map(
              (p): JSX.Element => (
                <div
                  onClick={(): void => navigate(`/${p.toLowerCase()}`)}
                  className="w-[150px] md:w-48 rounded-lg border cursor-pointer border-gray-300 relative transition-all hover:border-vera-green group">
                  <img
                    alt={p}
                    src={`/assets/party/${p}.svg`}
                    className="w-full h-48 p-5 border-b border-gray-300 group-hover:border-vera-green transition-all rounded-t-lg object-contain"
                  />
                  <div className=" w-full p-4 py-3 flex flex-col justify-between gap-2 flex-wrap wrap">
                    <p className="font-medium  break-words overflow-hidden  w-full">{p}</p>
                    <div className="flex justify-between items-center">
                      <button type="button" className="bg-vera-green-lightest p-1 rounded-lg w-fit">
                        <HiArrowRight className="w-6 h-6 mx-auto text-black" />
                      </button>
                      <div className="flex gap-1 items-center">
                        <HiUserGroup className="w-4 h-4" />
                        <p className="text-sm">{parties[p as keyof typeof parties].length}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            )}
        </div>
      </div>
    </div>
  );
}
