import React from 'react';
import { FaBuildingColumns } from 'react-icons/fa6';
import { AiOutlinePlus } from 'react-icons/ai';
import { Person } from '../types/types';
import { getInitials } from '../util/stringUtils';
import PersonLinks from './PersonLinks';
import ModalLayout from './ModalLayout';

interface InfoModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  person: Person | undefined;
}

export default function InfoModal({ open, setOpen, person }: InfoModalProps): JSX.Element {
  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      className="bg-white min-w-[300px] w-[45rem] p-8 flex flex-col  z-50 rounded-[20px] relative">
      {/* Header */}
      <div className="flex flex-col md:flex-row gap-2 md:gap-0 max-w-[45rem] w-full pb-8 border-b border-gray-300">
        <div className="rounded-xl w-28 h-28 mr-6 relative">
          {person?.picture ? (
            <div className="w-28 h-28 rounded-xl">
              <img src={person?.picture} alt="profile" className="object-cover object-top w-28 h-28 rounded-xl" />
            </div>
          ) : (
            <div className="w-28 h-28 border-dashed border-vera-green-darker border-2 rounded-xl flex items-center">
              <p className="mx-auto flex flex-col text-vera-green-darker text-[30px]">
                {getInitials(person?.fields.name || '')}
              </p>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2 w-full">
          <h1 className="font-medium mt-1 text-3xl">{person?.fields.name}</h1>
          <p className="text-sm">{person?.bio}</p>
        </div>
      </div>

      <div className="flex flex-col my-4 gap-2">
        <div className="flex items-center py-2">
          <FaBuildingColumns className="h-5 w-5 flex-none font-bold text-vera-green-darker icon" />
          <p className="text-sm font-normal text-gray-500 px-3 ">
            {person?.fields.study}, {person?.fields.school}
          </p>
        </div>
        {person && <PersonLinks person={person} />}
      </div>

      <button onClick={(): void => setOpen(false)} type="button" className="absolute top-5 right-5">
        <AiOutlinePlus className="h-7 w-7 rotate-45" />
      </button>
    </ModalLayout>
  );
}
