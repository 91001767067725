/* eslint-disable no-extra-boolean-cast */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { findPartyByName, getPartyName } from '../util/stringUtils';
import Table from '../components/Table';
import { getParty, getPolls } from '../util/dataUtils';
import { PartyType } from '../types/types';

export default function OverviewPage(): JSX.Element {
  const { party: parameter } = useParams();
  const type = findPartyByName(parameter || '');
  const party = getParty(type);
  const seats = getPolls(type);
  const navigate = useNavigate();

  useEffect((): void => {
    if (!type) navigate('/');
  }, []);

  if (!type || !party) return <></>;

  return (
    <div className="rounded-2xl bg-white md:max-w-7xl w-full min-h-[600px] justify-left p-5 md:p-10 mx-auto z-0 md:my-10 md:border mb-10 border-panel-border">
      <div className=" mb-6 w-ful flex justify-between">
        <div className="flex flex-col gap-1 md:w-5/6">
          <h1 className="text-3xl font-semibold tracking-tight text-gray-900">
            {getPartyName(type)}
          </h1>
          <p className="">
            Momenteel heeft deze partij in de verschillende{' '}
            <a
              target="_blank"
              href="https://www.peilingennederland.nl/alle-peilingen.html"
              className="underline font-medium"
              rel="noreferrer">
              peilingen
            </a>{' '}
            {seats.min === seats.max ? seats.min : `${seats.min}-${seats.max}`}{' '}
            {seats.min === seats.max && seats.min === 1 ? 'zetel' : 'zetels'}.{' '}
            {!!party.length
              ? 'Hieronder zijn de personen die volgens deze peilingen in de kamer komen lichtblauw gemarkeerd.'
              : "Helaas hebben wij voor deze partij geen bèta's  kunnen vinden."}
          </p>
        </div>
        <img
          alt={type}
          src={`/assets/party/${type}.svg`}
          className="w-20 h-12 ml-4 border-gray-300 group-hover:border-vera-green transition-all object-contain"
        />
      </div>
      {!!party.length && (
        <div className="overflow-x-scroll no-scrollbar">
          <Table
            party={party.sort((a, b): number => a.fields.position! - b.fields.position!)}
            type={type}
          />
        </div>
      )}
    </div>
  );
}
