import React from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import OptionsButton from './OptionsButton';
import { OptionsButtonOption, PartyType } from '../types/types';

export default function NavBar(): JSX.Element {
  const navigate = useNavigate();

  const options = Object.values(PartyType)
    .sort()
    .map(
      (p): OptionsButtonOption => ({
        name: p,
        linkTo: `/${p.toLowerCase()}`,
      }),
    );

  return (
    <div className="w-full h-16 sticky top-0 bg-white rounded-b-2xl flex border-panel-border border-b z-50 items-center">
      <div className="flex w-full max-w-7xl justify-between items-center mx-auto desktop:px-0 px-4">
          <img
            data-testid="nav-bar-logo-big"
            className="object-fit h-12 cursor-pointer"
            onClick={(): void => navigate('/')}
            src="./assets/logo.svg"
            alt="Vind de beta"
          />
        {window.location.pathname !== '/' && (
          <OptionsButton options={options}>
            <HiOutlineMenu className="h-6 w-6" />
          </OptionsButton>
        )}
      </div>
    </div>
  );
}
